<template>
  <div>
    <v-simple-table
      fixed-header
      height="calc(100vh - 145px)"
      class="table-padding-2-no-top"
    >
      <template v-slot:default>
        <thead class="v-data-table-header">
          <tr>
            <th role="columnheader">
              <InputFilter
                :label="$t('labels.receipt_session_tracking')"
                :placeholder="$t('labels.receipt_session_tracking')"
                name="tracking"
                sort-name="tracking"
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th role="columnheader">
              <InputFilter
                :label="$t('labels.barcode')"
                :placeholder="$t('labels.barcode')"
                name="customer_goods_barcode"
                sort-name="customer_goods_barcode"
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th role="columnheader">
              <InputFilterFromTo
                :label="$t('labels.goods_cost')"
                :placeholder="$t('labels.goods_cost')"
                name="cost"
                sort-name="cost"
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th role="columnheader">
              <InputFilter
                :label="$t('labels.goods_name')"
                :placeholder="$t('labels.goods_name')"
                name="goods_name"
                sort-name="goods_name"
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th role="columnheader">
              <InputFilter
                :label="$t('labels.goods_description')"
                :placeholder="$t('labels.goods_description')"
                name="goods_description"
                sort-name="goods_description"
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <!--<th role="columnheader">
                <InputFilterFromTo label="POS x Tồn" placeholder="POS x Tồn" name="pos_available" sort-name="pos_available"
                  :sorting="filters.sort_by" @onFilter="onFilterChange" @onSort="onSortChange" />
              </th>
              <th role="columnheader">
                <InputFilterFromTo label="KHO x Tồn" placeholder="KHO x Tồn" name="warehouse_available"
                  sort-name="warehouse_available" :sorting="filters.sort_by" @onFilter="onFilterChange"
                  @onSort="onSortChange" />
              </th>
              <th role="columnheader">
                <InputFilterFromTo label="Tổng tồn" placeholder="Tổng tồn" name="sum_available" sort-name="sum_available"
                  :sorting="filters.sort_by" @onFilter="onFilterChange" @onSort="onSortChange" />
              </th>-->
            <th role="columnheader">
              <InputFilter
                :label="$t('labels.identity_receipt')"
                :placeholder="$t('labels.identity_receipt')"
                name="identity_name"
                sort-name="identity_name"
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th role="columnheader">
              <DateRangeFilter
                :label="$t('labels.update_time')"
                :placeholder="$t('labels.update_time')"
                name="updated_at"
                sort-name="updated_at"
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th role="columnheader">
              <InputFilter
                :label="$t('labels.receipt_note')"
                :placeholder="$t('labels.receipt_note')"
                name="receipt_note"
                sort-name="receipt_note"
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th role="columnheader">
              <InputFilter
                :label="$t('labels.supplier')"
                :placeholder="$t('labels.supplier')"
                name="supplier_name"
                sort-name="supplier_name"
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            class="text-center"
            v-for="(item, key) in items"
            :key="`r_${key}_${item.id_goods}`"
          >
            <td><ReceiptTracking :tracking="item.goods_receipt_tracking" /></td>
            <td>
              <GoodsCode :code="item.customer_goods_barcode" />
            </td>
            <td>{{ item.cost != null ? formatNumber(item.cost) : "" }}</td>
            <td>{{ item.goods_name }}</td>
            <td>{{ item.goods_description }}</td>
            <!--<td>{{ item.pos_available }}</td>
              <td>{{ item.warehouse_available }}</td>
              <td>{{ item.sum_available }}</td>-->
            <td>{{ item.identity_name }}</td>
            <td>
              <span
                class="text-decoration-underline primary--text cursor-pointer"
                @click="showCostLog(item)"
              >
                {{ formatDateTime(item.updated_at) }}
              </span>
            </td>
            <td>{{ item.goods_receipt_detail_note }}</td>
            <td>
              <span v-if="item.supplier_code">
                {{ item.supplier_name }} - {{ item.supplier_code }}
              </span>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>

    <v-row class="pt-3">
      <v-col cols="12" sm="3">
        <div class="d-flex justify-start align-center pt-2">
          <v-btn color="info" small class="mr-2" @click="downloadExcel">
            <v-icon>mdi-download</v-icon> {{ $t("labels.excel") }}
          </v-btn>
          <v-btn color="success" small @click="showCostUpdate">
            {{ $t("labels.import_goods_cost") }}
          </v-btn>
        </div>
      </v-col>
      <v-col cols="12" sm="6">
        <v-pagination
          v-model="page"
          :length="totalPage"
          :total-visible="6"
        ></v-pagination>
      </v-col>
      <v-col cols="12" sm="2"></v-col>
      <v-col cols="12" sm="1">
        <div class="d-flex">
          <v-text-field
            class="c-input-xs mr-2"
            dense
            outlined
            hide-details
            v-model.number="page"
            :label="$t('labels.page')"
            :placeholder="$t('labels.page')"
          ></v-text-field>
        </div>
      </v-col>
    </v-row>

    <v-dialog v-model="dialogCostUpdate" persistent max-width="800px">
      <CostUpdate
        v-if="dialogCostUpdate"
        @cancel="hideCostUpdate"
        @refreshData="getList"
      />
    </v-dialog>

    <v-dialog v-model="dialogCostLog" persistent max-width="400px">
      <CostLog
        v-if="dialogCostLog"
        :updating-item="updatingItem"
        @close="hideCostLog"
      />
    </v-dialog>
  </div>
</template>

<script>
import { IMAGE_OPTIONS, YES_NO_OPTIONS } from "@/libs/const";
import { debounce } from "lodash/function";
import { httpClient } from "@/libs/http";

export default {
  name: "GoodsCost",
  components: {
    DateRangeFilter: () => import("@/components/table/DateRangeFilter"),
    InputFilter: () => import("@/components/table/InputFilter"),
    InputFilterFromTo: () => import("@/components/table/InputFilterFromTo"),
    CostUpdate: () => import("@/components/goods/CostUpdate"),
    CostLog: () => import("@/components/goods/CostLog"),
    GoodsCode: () => import("@/components/common/GoodsCode"),
    ReceiptTracking: () => import("@/components/common/ReceiptTracking"),
  },
  data: () => ({
    isLoading: false,
    items: [],
    page: 1,
    totalPage: 1,
    filters: {},
    statusOptions: [...YES_NO_OPTIONS],
    imageOptions: [...IMAGE_OPTIONS],
    dialogCostUpdate: false,
    dialogCostLog: false,
    updatingItem: {},
  }),
  computed: {},
  watch: {
    page() {
      this.getList();
    },
    filters: {
      handler() {
        this.page = 1;
        this.getList();
      },
      deep: true,
    },
  },
  mounted() {
    this.getList();
  },
  methods: {
    getList: debounce(function () {
      httpClient
        .post("/goods-get-cost-by-receipt", {
          ...this.filters,
          page: this.page,
        })
        .then(({ data }) => {
          this.totalPage = data.totalPage;
          this.items = [...data.rows];
        });
    }, 1000),

    async downloadExcel() {
      if (this.isLoading) {
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }
      this.isLoading = true;
      const filename = "hang-hoa-gia-von.xlsx";

      try {
        await this.downloadExcelFile(
          "/goods-get-cost-by-receipt-export",
          { ...this.filters },
          filename
        );
        this.isLoading = false;
      } catch (e) {
        this.isLoading = false;
      }
    },

    onFilterChange(filter) {
      this.filters = { ...this.filters, [filter.name]: filter.value };
    },
    onSortChange(sort) {
      this.filters = { ...this.filters, ...sort };
    },
    showCostUpdate() {
      this.dialogCostUpdate = true;
    },
    hideCostUpdate() {
      this.updatingItem = {};
      this.dialogCostUpdate = false;
    },
    showCostLog(item) {
      this.updatingItem = { ...item };
      this.dialogCostLog = true;
    },
    hideCostLog() {
      this.updatingItem = {};
      this.dialogCostLog = false;
    },
  },
};
</script>

<style scoped></style>
